import { Fragment, FunctionComponent, ChangeEvent, FormEvent } from "react";
import Players from "../interfaces/PlayersInterface";
import PageBackButton from "./PageBackButton";

interface NamePlayersProps {
  onChangePlayerNames: (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  playerNamesCompletelyEntered: boolean;
  onSubmitPlayerNames: (event: FormEvent<HTMLFormElement>) => void;
  onPreviousPage: () => void;
  players: Players[];
}

const NamePlayers: FunctionComponent<NamePlayersProps> = ({
  onChangePlayerNames,
  playerNamesCompletelyEntered,
  onSubmitPlayerNames,
  onPreviousPage,
  players,
}) => {
  return (
    <Fragment>
      <h2 className="text-2xl text-center">Name Players</h2>
      <form onSubmit={onSubmitPlayerNames}>
        {players.map((_, i) => {
          return (
            <input
              key={i}
              className="text-field"
              autoFocus={i === 0}
              spellCheck={false}
              type="text"
              placeholder={`Player ${i + 1} Name`}
              onChange={(event) => onChangePlayerNames(event, i)}
              value={players[i].name}
              data-index={i}
            />
          );
        })}
        <PageBackButton onPreviousPage={onPreviousPage} />
        <button
          className="btn-primary"
          disabled={playerNamesCompletelyEntered}
          type="submit"
        >
          Submit
        </button>
      </form>
    </Fragment>
  );
};

export default NamePlayers;
