import { FunctionComponent } from "react";
import Player from "../interfaces/PlayersInterface";
import UpcomingPlayer from "../interfaces/UpcomingPlayersInterface";
import Phase from "./Phase";

interface ScoreboardListItemProps {
  player: Player;
  isDealer: boolean;
  index: number;
  upcomingPlayers: UpcomingPlayer[];
  phaseNumber: number;
}

const ScoreboardListItem: FunctionComponent<ScoreboardListItemProps> = ({
  player,
  isDealer,
  phaseNumber,
}) => {
  const color = [
    "text-red-500",
    "text-orange-500",
    "text-yellow-600",
    "text-green-600",
    "text-blue-500",
    "text-purple-600",
    "text-indigo-800",
    "text-pink-600",
    "text-gray-600",
    "text-slate-900",
  ];

  return (
    <>
      <div>
        <div
          className={`p-2 text-3xl rounded-l-lg text-center font-bold ${
            isDealer ? "bg-green-200" : "bg-slate-300"
          }`}
        >
          {player.name} {isDealer && <span className="text-lg">(Dealer)</span>}
        </div>
        <div className="p-2">
          <div className="text-5xl font-mono my-auto">
            {player.score.reduce((partialSum, a) => partialSum + a, 0)}
          </div>
        </div>
      </div>
      <div className={`p-2 bg-slate-300 ${color[phaseNumber]}`}>
        <div className="font-bold font-courier font-mono text-3xl whitespace-nowrap">
          <svg
            className="inline align-baseline"
            width="16"
            height="20"
            fill="currentColor"
          >
            <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z" />
          </svg>
          {player.phase.filter((val) => val === true).length + 1}
          <svg
            className="inline align-baseline"
            width="16"
            height="20"
            fill="currentColor"
          >
            <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z" />
          </svg>
        </div>
        <div>
          <Phase phaseNumber={phaseNumber} />
        </div>
      </div>
    </>
  );
};

export default ScoreboardListItem;
