import React, { FunctionComponent } from "react";
import "./App.css";
import Game from "./components/Phase10/Game";

const App: FunctionComponent = () => {
  return (
    <div className="App">
      <Game />
    </div>
  );
};

export default App;
