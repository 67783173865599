import { FunctionComponent } from "react";

interface PageBackButtonProps {
  onPreviousPage: () => void;
}

const PageBackButton: FunctionComponent<PageBackButtonProps> = ({
  onPreviousPage,
}) => {
  return (
    <a href="#" className="btn-secondary" onClick={onPreviousPage}>
      Back
    </a>
  );
};

export default PageBackButton;
