import { Fragment, FunctionComponent, useState } from "react";
import Player from "../interfaces/PlayersInterface";

interface LeaderboardProps {
  players: Player[];
  isLastPhaseComplete: boolean;
}

const Leaderboard: FunctionComponent<LeaderboardProps> = ({
  players,
  isLastPhaseComplete,
}) => {
  const [editFlag, setEditFlag] = useState(false);

  return (
    <>
      <h2 className="text-xl font-bold">{`${
        isLastPhaseComplete ? "Final Scores" : "Leaderboard"
      }`}</h2>
      <table className="mx-auto">
        <thead>
          <tr>
            <th colSpan={5}></th>
            {players[0].score.map((score, index) => (
              <td key={index}>{index + 1}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...players]
            .sort(
              (a, b) =>
                a.score.reduce((partialSum, a) => partialSum + a, 0) -
                b.score.reduce((partialSum, a) => partialSum + a, 0)
            )
            .map((player, index) => (
              <tr key={index}>
                <th className="">#{index + 1}</th>
                <th className="text-center">{index === 0 && "🥇"}</th>
                <th className="text-left">{player.name}</th>
                <th className="text-center">-</th>
                <th className="text-right">
                  {player.score.reduce((partialSum, a) => partialSum + a, 0)}
                </th>
                {player.score.map((score, index) => (
                  <td
                    key={index}
                    className={`text-right p-1 ${
                      player.phase[index] && "bg-green-500"
                    }`}
                  >
                    {score}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default Leaderboard;
