import { FunctionComponent } from "react";

interface ResetGameButtonProps {
  onResetGame: () => void;
  isLastGame: boolean;
}

const ResetGameButton: FunctionComponent<ResetGameButtonProps> = ({
  onResetGame,
  isLastGame,
}) => {
  const title = isLastGame ? "New Game" : "Reset Game";
  return (
    <button
      className="bg-slate-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 erase-linear transition-all duration-150"
      type="button"
      onClick={() => {
        window.confirm(`${title}?`) && onResetGame();
      }}
    >
      {title}
    </button>
  );
};

export default ResetGameButton;
