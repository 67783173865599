import {
  ChangeEvent,
  FormEvent,
  Fragment,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import ResetGameButton from "./ResetGameButton";
import update from "immutability-helper";
import Player from "../interfaces/PlayersInterface";
import NamePlayers from "./NamePlayers";
import Scoreboard from "./Scoreboard";
import ScoreboardList from "./ScoreboardList";
import ScoreboardListItem from "./ScoreboardListItem";
import SelectPlayerCount from "./SelectPlayerCount";
import UpcomingPlayer from "../interfaces/UpcomingPlayersInterface";
import Modal from "../Modal";
import Leaderboard from "./Leaderboard";

enum PAGES {
  SELECT_PLAYER_COUNT,
  NAME_PLAYERS,
  SCOREBOARD,
  SCORING,
}

interface GameProps {}

const Game: FunctionComponent<GameProps> = () => {
  const exceptTheseSymbols = ["e", "E", "+", "-", "."];

  const [page, setPage] = useState(0);

  const [players, setPlayers] = useState<Player[]>([]);
  const [upcomingPlayers, setUpcomingPlayers] = useState<UpcomingPlayer[]>([]);

  const [hand, setHand] = useState<number>(1);

  const [winner, setWinner] = useState<number | undefined>();

  const [showModal, setShowModal] = useState(false);

  const resetGame = () => {
    setPlayers([]);
    setUpcomingPlayers([]);
    setHand(1);
    setWinner(undefined);
    setPage(0);
  };

  useEffect(() => {
    const data = window.localStorage.getItem("MY_APP_STATE");
    // if ( data !== null ) setShowBanner(JSON.parse(data));
    if (data !== null) {
      const state = JSON.parse(data);
      setPage(state.page);
      setPlayers([...state.players]);
      setUpcomingPlayers([...state.upcomingPlayers]);
      setHand(state.hand);
      setWinner(state.winner);
      setShowModal(state.showModal);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      "MY_APP_STATE",
      JSON.stringify({
        page: page,
        players: players,
        upcomingPlayers: upcomingPlayers,
        hand: hand,
        winner: winner,
        showModal: showModal,
      })
    );
  }, [page, players, upcomingPlayers, hand, winner, showModal]);

  const handleSetShowModal = (val: boolean) => {
    setShowModal(val);
  };

  const handleChooseNumberOfPlayers = (count: number) => {
    const newPlayers = new Array(count).fill({
      name: "",
      score: [],
      phase: [],
    });
    setPlayers(newPlayers);

    nextPage();
  };

  const handleChangePlayerNames = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = event.target;

    let newPlayers = update(players, { [+index]: { name: { $set: value } } });
    setPlayers(newPlayers);
  };

  const handleSubmitPlayerNames = (event: FormEvent<HTMLFormElement>) => {
    const setRandomDealer = () => {
      const { length } = players;
      const randomPlayerIndex = Math.trunc(Math.random() * length);
      const newPlayers = [...players, ...players].slice(
        randomPlayerIndex,
        randomPlayerIndex + length
      );
      setPlayers(newPlayers);
      resetUpcomingPlayers();
    };

    event.preventDefault();
    setRandomDealer();
    nextPage();
  };

  const handlePreviousPage = () => {
    prevPage();
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const incrementHand = () => {
    setHand(hand + 1);
  };

  const playerNamesCompletelyEntered = () => {
    let val =
      players.filter((player) => player.name.length > 0).length !==
      players.length;
    return val;
  };

  const playerIsDealer = (index: number) => {
    return (hand - 1) % players.length === index;
  };

  const resetUpcomingPlayers = () => {
    let newUpcomingPlayers = [];
    for (let i = 0; i < players.length; i++) {
      newUpcomingPlayers.push({ score: 0, phased: false });
    }
    setUpcomingPlayers(newUpcomingPlayers);
  };

  const handleChangePhaseValue = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    phased?: boolean
  ) => {
    let checked;
    if (phased === undefined) {
      checked = event.target.checked;
    } else {
      checked = phased;
    }

    let newUpcomingPlayers = update(upcomingPlayers, {
      [index]: { phased: { $set: checked } },
    });
    setUpcomingPlayers(newUpcomingPlayers);
  };

  const handleChangeScoreEditFieldValue = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    let newUpcomingPlayers = update(upcomingPlayers, {
      [index]: { score: { $set: +value } },
    });
    setUpcomingPlayers(newUpcomingPlayers);
  };

  const handleSaveChanges = () => {
    let newPlayers = [...players];
    newPlayers.forEach((player, index) => {
      player.score = [...player.score, upcomingPlayers[index].score];
      player.phase = [...player.phase, upcomingPlayers[index].phased];
    });
    setPlayers(newPlayers);
    incrementHand();
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setWinner(undefined);
    resetUpcomingPlayers();
  };

  const isLastPhaseComplete = () => {
    const val = Math.max(
      ...players.map(
        ({ phase }) => phase.filter((phase) => phase === true).length
      )
    );
    return val >= 10;
  };

  return (
    <div className="mb-14">
      <div className="text-3xl text-center">Phase 10</div>
      {page === PAGES.SELECT_PLAYER_COUNT && (
        <SelectPlayerCount
          onChooseNumberOfPlayers={handleChooseNumberOfPlayers}
        />
      )}
      {page === PAGES.NAME_PLAYERS && (
        <>
          <NamePlayers
            onChangePlayerNames={handleChangePlayerNames}
            onSubmitPlayerNames={handleSubmitPlayerNames}
            players={players}
            onPreviousPage={handlePreviousPage}
            playerNamesCompletelyEntered={playerNamesCompletelyEntered()}
          />
        </>
      )}
      {page >= PAGES.SCOREBOARD && !isLastPhaseComplete() && (
        <>
          <Scoreboard hand={hand}>
            <ScoreboardList>
              <>
                {players.map((player, index) => (
                  <ScoreboardListItem
                    key={index}
                    index={index}
                    player={player}
                    upcomingPlayers={upcomingPlayers}
                    isDealer={playerIsDealer(index)}
                    phaseNumber={
                      player.phase.filter((val) => val === true).length
                    }
                  />
                ))}
              </>
            </ScoreboardList>
          </Scoreboard>
        </>
      )}
      {page >= PAGES.SCOREBOARD && !isLastPhaseComplete() && (
        <>
          <Modal
            onSaveChanges={handleSaveChanges}
            onCloseModal={handleCloseModal}
            modalTitle={"Score this hand!"}
            showButton={!isLastPhaseComplete()}
            disableSaveButton={
              winner === undefined ||
              upcomingPlayers
                .map(({ score }) => score)
                .filter((num) => num === 0).length -
                1 !==
                0
            }
            setShowModal={handleSetShowModal}
            showModal={showModal}
            // resetGame={resetGame}
          >
            <>
              <div className="font-bold">Which player won?</div>
              <div className="m-4 space-x-0.">
                {players.map((player, index) => {
                  return (
                    <Fragment key={index}>
                      <label
                        htmlFor={`winnerName${index}`}
                        className={`first:rounded-l-full last:rounded-r-full ring-blue-300 bg-transparent font-semibold py-2 px-4 border border-blue-500 ${
                          winner === index
                            ? "bg-blue-500 text-white border-transparent"
                            : "text-blue-700 "
                        } ${
                          winner !== undefined
                            ? "opacity-50"
                            : "hover:ring-2 cursor-pointer "
                        }`}
                      >
                        <input
                          type="radio"
                          name="winner"
                          id={`winnerName${index}`}
                          value={index}
                          onChange={(e) => {
                            setWinner(index);
                            handleChangePhaseValue(e, index, true);
                          }}
                          className="hidden"
                          disabled={winner !== undefined}
                        />
                        {player.name}
                      </label>
                    </Fragment>
                  );
                })}
              </div>
              {winner !== undefined && (
                <>
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <div className="table w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <div className="table-header-group text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <div className="table-row">
                          <div className="table-cell text-left px-6 py-3">
                            Who else phased?
                          </div>
                          <div className="table-cell text-left px-6 py-3">
                            Score
                          </div>
                        </div>
                      </div>
                      <div className="table-row-group">
                        {players.map((player, index) => (
                          <div className="table-row" key={index}>
                            {index !== winner && (
                              <>
                                <div className="table-cell text-center">
                                  <label
                                    htmlFor={`phasedName${index}`}
                                    className={`bg-transparent font-semibold py-2 px-4 border border-blue-500 rounded hover:ring-2 cursor-pointer ${
                                      upcomingPlayers[index]?.phased === true
                                        ? "bg-blue-500 text-white border-transparent"
                                        : "text-blue-700 "
                                    } `}
                                  >
                                    <input
                                      type="checkbox"
                                      name="phased"
                                      id={`phasedName${index}`}
                                      value={index}
                                      onChange={(e) =>
                                        handleChangePhaseValue(e, index)
                                      }
                                      className="hidden"
                                    />
                                    {player.name}
                                  </label>
                                </div>
                                <div className="table-cell text-left">
                                  <input
                                    className="text-field p-2 inline-block w-full"
                                    // key={`text-field-score-for-player-${playerIndex}`}
                                    // id={`text-field-score-for-player-${playerIndex}`}
                                    placeholder="Score"
                                    type="number"
                                    pattern="[0-9]*"
                                    onClick={(e) => {
                                      const element =
                                        e.target as HTMLInputElement;
                                      element.select();
                                    }}
                                    min="0"
                                    onKeyDown={(e) =>
                                      exceptTheseSymbols.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    value={upcomingPlayers[index].score}
                                    onChange={(e) =>
                                      handleChangeScoreEditFieldValue(e, index)
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </Modal>
        </>
      )}
      {page >= PAGES.SCOREBOARD && (
        <ResetGameButton
          onResetGame={resetGame}
          isLastGame={isLastPhaseComplete()}
        />
      )}

      {isLastPhaseComplete() && (
        <Leaderboard
          isLastPhaseComplete={isLastPhaseComplete()}
          players={players}
        />
      )}
    </div>
  );
};

export default Game;
