import { FunctionComponent } from "react";

interface ScoreboardListProps {
  children: JSX.Element;
}

const ScoreboardList: FunctionComponent<ScoreboardListProps> = ({
  children,
}) => {
  return (
    <div className="grid gap-y-4 grid-cols-2 w-full sm:3/4  mx-auto m-2">
      {children}
    </div>
  );
};
export default ScoreboardList;
