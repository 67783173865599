import { Fragment, FunctionComponent } from "react";

interface SelectPlayerCountProps {
  onChooseNumberOfPlayers: (count: number) => void;
}

const SelectPlayerCount: FunctionComponent<SelectPlayerCountProps> = ({
  onChooseNumberOfPlayers,
}) => {
  return (
    <Fragment>
      <h2 className="text-2xl text-center">How Many Players?</h2>
      <div className="m-2 text-center">
        {new Array(5).fill(0).map((_, i) => {
          const numberOfPlayers = i + 2;
          return (
            <Fragment key={i}>
              <button
                className="inline disabled:bg-gray-700 hover:disabled:bg-gray-700 first:rounded-l-full last:rounded-r-full text-white bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium text-xl ml-0.5 px-5 py-2 5 mr-0 5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                onClick={() => onChooseNumberOfPlayers(numberOfPlayers)}
              >
                {numberOfPlayers}
              </button>
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

export default SelectPlayerCount;
