import { Fragment, FunctionComponent } from "react";
import reactStringReplace from "react-string-replace";
import PhaseCard from "./PhaseCard";

interface PhasesProps {
  phaseNumber: number;
}

const Phases: FunctionComponent<PhasesProps> = ({ phaseNumber }) => {
  const phases = [
    { description: ["2 sets of 3"], values: ["AAA", "BBB"] },
    { description: ["1 set of 3", "1 run of 4"], values: ["AAA", "ABCD"] },
    { description: ["1 set of 4", "1 run of 4"], values: ["AAAA", "ABCD"] },
    { description: ["1 run of 7"], values: ["ABCDEFG"] },
    { description: ["1 run of 8"], values: ["ABCDEFGH"] },
    { description: ["1 run of 9"], values: ["ABCDEFGHI"] },
    { description: ["2 sets of 4"], values: ["AAAA", "BBBB"] },
    { description: ["7 cards of one color"], values: ["0000000"] },
    { description: ["1 set of 5", "1 set of 2"], values: ["AAAAA", "BB"] },
    { description: ["1 set of 5", "1 set of 3"], values: ["AAAAA", "BBB"] },
  ];

  const phase = phases[phaseNumber];
  return (
    <div>
      <div className="text-xl font-bold">
        {phase.description.map((text, index) => (
          <Fragment key={index}>
            <div>{text}</div>
          </Fragment>
        ))}
      </div>
      {phase.values.map((value, index) => {
        return <div key={index}>{parseValue(value)}</div>;
      })}
    </div>
  );
};

const parseValue = (value: string) => {
  const newValue = reactStringReplace(value, /(.)/g, (match, i) => {
    return (
      <PhaseCard key={i} className={`${match === "0" && "bg-red-300"}`}>
        {<Fragment>{match}</Fragment>}
      </PhaseCard>
    );
  });
  return newValue;
};

export default Phases;
