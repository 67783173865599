import { FunctionComponent } from "react";

interface PhaseCardProps {
  children: JSX.Element;
  className?: string;
}

const PhaseCard: FunctionComponent<PhaseCardProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={`inline-block font-bold text-center p-0.5 w-6 h-9 rounded-sm border-solid border-2 border-black m-0.5 ${className}`}
    >
      {children}
    </div>
  );
};

export default PhaseCard;
